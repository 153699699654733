@import "./variables";

* {
    -webkit-tap-highlight-color: transparent;
    box-sizing: border-box;

    &:focus {
        outline: none;
        -webkit-tap-highlight-color: transparent;
    }
}

#root {
    min-height: 100vh;
}

::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

::-webkit-scrollbar-track {
    background: darkBlue(0.1);
}

::-webkit-scrollbar-thumb {
    background: $darkBlue;
}

::-webkit-scrollbar-thumb:hover {
    background: darkBlue(0.7);
}

@media only screen and (max-width: 768px) {
    html.menu-open {
        overflow-y: hidden;
    }
}

body {
    font-family: "Open Sans", sans-serif;
    margin: 0;
    padding: 0;
    background: $offWhite;
    color: $darkBlue;
    overflow-x: hidden;
    width: 100%;
    min-height: 100vh;
}

h1, h2, h3, h4 {
    @include MerriweatherBold;
}

a {
    color: $darkBlue;
    text-decoration: none;
    position: relative;
    font-weight: bold;
    letter-spacing: 1px;

    @include line-on-hover($darkBlue);
}
