@import "../../../ui/variables";

.form_control {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    padding-bottom: 10px;
    @include OpenSansRegular;

    &:not(:last-of-type) {
        margin-bottom: 10px;
    }

    label {
        width: 100%;
        display: flex;
        align-items: center;

        input {
            width: 100%;
            font-size: 18px;
            border: 1px solid $darkBlue;
            background: $offWhite;
            box-sizing: border-box;
            padding: 10px;

            &:focus + span,
            &.filled + span {
                top: -9px;
                font-size: 14px;
                left: 10px;
            }

            &:focus {
                border-width: 2px;
                margin-top: -1px;
                margin-bottom: -1px;
            }
        }

        span {
            $padding: 5px;
            position: absolute;
            font-size: 18px;
            top: 8px;
            left: 10px;
            background: $offWhite;
            padding: 0 $padding;
            color: $darkBlue;
            transition: $transition;
            cursor: text;
            overflow: hidden;
            max-width: calc(100% - (4 * #{$padding}));
            text-overflow: ellipsis;
        }
    }
}
