@import "../../../../ui/variables";

.wrapper {
    i {
        display: block;
        padding: $spacing;
    }

    .table {
        margin: $spacing;
        border-collapse: collapse;

        tr {
            vertical-align: top;

            > * {
                padding-right: $spacing;
                text-align: left;
                padding-top: $spacing / 2;
                padding-bottom: $spacing / 2;
            }
        }

        tbody > tr:not(:last-of-type) {
            border-bottom: 1px solid $darkBlue;
        }

        .order {
            display: flex;
            flex-direction: column;

            .cd {
                display: flex;
                flex-direction: row;

                .detail {
                    display: flex;
                    flex-direction: row;

                    &:first-of-type {
                        margin-right: $spacing / 2;
                    }

                    .title {
                        font-weight: bold;
                        margin-right: $spacing / 4;
                    }
                }
            }
        }

        .buttons b {
            color: $darkBlue;
            cursor: pointer;
            pointer-events: all;

            &:first-of-type {
                margin-right: $spacing / 2;
            }
        }
    }
}
