@import "../../../../ui/variables";

.content {
    width: calc(70vw - 4 * #{$spacing});
    margin-left: auto;
    margin-right: auto;
    padding: $spacing 0;
    max-width: 1400px;
}

@media only screen and(max-width: 768px) {
    .content {
        width: calc(90vw - 4 * #{$spacing});
    }
}
