@import "../../../../ui/variables";

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;

    .content {
        text-align: center;
        width: 250px;
        max-width: 80vw;
        position: relative;

        .text {
            @include MerriweatherBold;
            font-size: 20px;
            margin-top: 3 * $spacing;
        }
    }
}
