@import "../../../../ui/variables";

@mixin title {
    .title {
        @include MerriweatherBold;
        font-size: 24px;
        margin-bottom: $spacing / 2;
        margin-left: $spacing;

        &:after {
            display: block;
            content: "";
            height: 3px;
            width: 80px;
            margin-top: $spacing / 4;
            background: $darkBlue;
        }
    }
}

.wrapper {
    width: 70vw;
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
    padding: 3 * $spacing 0 $spacing;

    .photos i, .videos i {
        margin-left: $spacing;
    }

    .photos {
        margin-bottom: 2 * $spacing;
        @include title;

        .photo_wrapper {
            img {
                padding: $spacing;
                max-width: 300px;
                max-height: 300px;
            }
        }
    }

    .videos {
        @include title;

        .video_wrapper {
            .video {
                width: 600px;
                height: 360px;
                padding: $spacing;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .wrapper {
        $width: 90vw;
        width: $width;

        .videos .video_wrapper .video {
            width: $width;
            height: calc(#{$width} * (9 / 16));
        }
    }
}
