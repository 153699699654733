@import "../../../ui/variables";

.wrapper {
    $base-width: 50vw;
    $img-width: 300px;

    @include OpenSansRegular;
    box-shadow: 0 -10px 10px -5px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: row;
    font-size: 1.3em;
    color: $darkBlue;
    letter-spacing: 1px;
    justify-content: center;
    min-width: calc(#{$base-width} - (2 * #{$spacing}));

    &.with_img > p {
        min-width: calc(#{$base-width} - #{$img-width} - (2 * #{$spacing}));
    }

    &.with_double_img > p {
        min-width: calc(#{$base-width} - (2 * (#{$img-width} + #{$spacing})));
    }

    > * {
        margin: 2 * $spacing;
    }

    p {
        max-width: 800px;
        font-size: 0.85em;
        white-space: pre-line;
    }

    .img_wrapper {
        width: $img-width;
        margin-left: 0;
        margin-right: 0;

        i {
            font-size: 16px;
            text-align: center;
            display: block;
        }

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
}

@media only screen and (max-width: 768px) {
    .wrapper {
        $base-width: 50vw;
        $img-width: 300px;

        flex-direction: column;
        min-width: calc(#{$base-width} - (2 * #{$mobile-spacing}));

        &.with_img > p {
            min-width: calc(#{$base-width} - #{$img-width} - (2 * #{$mobile-spacing}));
        }

        &.with_double_img > p {
            min-width: calc(#{$base-width} - (2 * (#{$img-width} + #{$mobile-spacing})));
        }

        p {
            text-align: center;
            font-size: 0.6em;
        }

        > * {
            margin: 2 * $mobile-spacing;
        }

        .img_wrapper {
            margin-left: auto;
            margin-right: auto;
            margin-top: 0;
        }
    }
}
