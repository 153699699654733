@import "../../../../ui/variables";

.page {
    width: 100vw;
    height: 100vh;
    background-image: url("../../../../assets/images/003.jpg");
    background-size: cover;
    background-position: center right;
    position: relative;

    .form {
        background: $offWhite;
        padding: 3 * $spacing 2 * $spacing;
        padding-bottom: 2 * $spacing;
        position: absolute;
        bottom: 0;
        left: $spacing;
        width: 600px;
        max-width: 80vw;

        h1 {
            margin: 0 0 $spacing * 2;
            font-size: 36px;
        }

        .input {
            margin-bottom: $spacing;
        }

        hr {
            margin: $spacing 0;
        }

        .credits tr {
            display: block;

            th {
                text-align: left;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .page {
        background-position: center center;

        .form {
            width: unset;
            max-width: unset;
            left: $spacing;
            right: $spacing;
            padding-bottom: 3 * $spacing;
        }
    }
}
