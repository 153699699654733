@import "../../../../ui/variables";

.page {
    .header {
        h1 {
            @include MerriweatherBold;
            font-size: 3em;
            color: $white;
            margin-bottom: 0;
            margin-top: calc(0.83em - #{$spacing});
        }

        h3 {
            @include MerriweatherLightItalic;
            color: $white;
            font-size: 2em;
        }
    }
}

@media only screen and (max-width: 768px) {
    .page .header {
        h1 {
            font-size: 2.2em;
        }

        h3 {
            font-size: 1.5em;
        }
    }
}
