@import "../../../ui/variables";

.header {
    height: 40vh;
    max-height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    padding-top: 56px + 2 * $spacing;
    text-align: center;

    .img {
        min-width: 100%;
        z-index: -1;
        position: absolute;
        filter: brightness(0.5);
        transform: translateY(-24px);

        &.five {
            transform: translateY(160px);
        }
    }
}

@media only screen and(max-width: 768px) {
    .header {
        height: 50vh;

        .img {
            top: 55px;
            transform: unset;
            height: 100%;
            width: unset;
            min-width: unset;
            max-width: unset;

            &.five {
                transform: unset;
            }
        }
    }
}
