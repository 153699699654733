@import "../../../../ui/variables";

.page {
    width: 70vw;
    margin-left: auto;
    margin-right: auto;
    padding: $spacing 0;
    flex: 1;
    display: flex;
    flex-direction: column;

    .search {
        border: 1px solid $darkBlue;
        transition: $transition;
        display: flex;
        align-items: center;
        padding: 0.25 * $spacing;
        width: 100%;
        position: relative;
        margin-bottom: $spacing;

        &.focused {
            box-shadow: 0 0 10px 3px darkBlue(0.1);
        }

        .input {
            flex: 1;
            align-items: center;
            display: flex;
            margin-left: $spacing;

            &:not(:first-of-type):before {
                content: "";
                display: block;
                height: 60%;
                width: 1px;
                background: $darkBlue;
                position: absolute;
                transform: translateX(-$spacing);
            }

            input {
                font-size: 1em;
                background: none;
                border: none;
                color: $darkBlue;
                width: 100%;
                padding: 5px 0;
                @include OpenSansRegular;

                &::placeholder {
                    color: $darkBlue;
                }
            }

            .img {
                width: $spacing;
                height: $spacing;
                margin-right: 0.5 * $spacing;
                margin-left: -8px;
            }
        }

        .button {
            flex: unset;
            background: $darkBlue;
            border: none;
            letter-spacing: 2px;
            padding: 8px 20px;
            text-transform: uppercase;
            color: $offWhite;
            position: relative;
            transition: $transition;
            cursor: pointer;
            @include ripple($offWhite);
            @include OpenSansBold;

            &:hover {
                background: darkBlue(0.8);
            }
        }
    }

    .events {
        margin-top: 30px;
        display: flex;
        overflow-x: hidden;
        position: relative;
        flex: 1;
    }
}

@media only screen and (max-width: 768px) {
    .page {
        width: 100vw;
        padding: $spacing;

        .search {
            flex-direction: column;
            border: none;

            &.focused {
                box-shadow: none;
            }

            .input {
                margin: $spacing / 4 0;
                padding: 0 $spacing / 2 $spacing / 3;
                position: relative;
                width: 100%;

                &:not(:first-of-type):before,
                &:last-of-type:after {
                    content: "";
                    background: $darkBlue;
                    display: block;
                    position: absolute;
                    height: 1px;
                    width: calc(100% + (#{$spacing} / 2));
                    right: -$spacing / 4;
                }

                &:not(:first-of-type):before {
                    transform: translateY(-$spacing);
                }

                &:last-of-type:after {
                    transform: translateY($spacing);
                }

                .img {
                    margin-left: 0;
                }
            }

            .button {
                margin-top: $spacing;
            }
        }
    }
}

