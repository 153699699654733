@import "../../../ui/variables";

.loader_wrapper {
    position: absolute;
    width: 100%;
    top: 0;
    opacity: 0;

    &.loading {
        opacity: 1;
    }

    &.blue {
        background: darkBlue(0.3);

        .loader_bg .loader_indicator {
            background: $darkBlue;
        }
    }

    &.white {
        background: offWhite(0.3);

        .loader_bg .loader_indicator {
            background: $offWhite;
        }
    }

    &.red {
        background: lightRed(0.3);

        .loader_bg .loader_indicator {
            background: $lightRed;
        }
    }

    .loader_bg {
        height: 4px;
        overflow: hidden;
        position: relative;

        .loader_indicator {
            width: auto;
            height: 4px;
            position: absolute;

            &.indeterminate {
                animation: linear-indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
            }

            &.determinate {
                left: 0;
            }
        }
    }

    @-webkit-keyframes linear-indeterminate {
        0% {
            left: -35%;
            right: 100%;
        }

        60% {
            left: 100%;
            right: -90%;
        }

        100% {
            left: 100%;
            right: -90%;
        }
    }

    @keyframes linear-indeterminate {
        0% {
            left: -35%;
            right: 100%;
        }

        60% {
            left: 100%;
            right: -90%;
        }

        100% {
            left: 100%;
            right: -90%;
        }
    }
}
