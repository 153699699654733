@import "../../../../ui/variables";

.progressbar {
    position: relative;
}

.overview .wrapper {
    display: inline-flex;
    flex-direction: row;
    padding: 2 * $spacing;
    max-width: 50%;
    vertical-align: top;

    &:after {
        display: none;
    }

    .column {
        display: flex;
        flex-direction: column;
        justify-content: center;

        &:not(:last-of-type) {
            margin-right: 3 * $spacing;
        }

        .image {
            $size: 200px;
            width: $size;
            height: $size;
            display: flex;
            align-items: center;
            justify-content: center;

            &.hidden {
                background: $lightBlue;

                img {
                    display: none;
                }

                .title {
                    color: $offWhite;
                    margin-bottom: 0;
                }
            }

            img {
                max-width: $size;
                max-height: $size;
            }

            .title {
                display: none;
            }
        }

        .title {
            @include MerriweatherBold;
            font-size: 24px;
            margin-bottom: $spacing;
        }

        .amount {
            @include MerriweatherBold;
            font-size: 20px;
        }

        .additional {
            font-size: 12px;
            @include OpenSansRegular;
        }
    }
}

@media only screen and (max-width: 1400px) {
    .overview {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .wrapper {
            flex-direction: column;
            display: flex;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
            max-width: 800px;

            .column {
                margin-right: 0 !important;

                .image {
                    margin-left: auto;
                    margin-right: auto;
                    max-width: 100%;

                    img {
                        max-width: 100%;
                    }
                }

                .title {
                    margin: $spacing 0;
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .overview .wrapper {
        padding: $spacing 0;
        max-width: unset;
    }
}
